// ? These components function with alpine + tailwind UI components
// import Swiper, {Navigation, Pagination, Mousewheel, EffectCreative, Autoplay, FreeMode} from 'swiper'
// import Swiper from 'swiper'
// import {Autoplay, EffectCreative, Mousewheel, Navigation, Pagination} from 'swiper/modules'
import {Loader} from '@googlemaps/js-api-loader'

// AIzaSyCXMvWvmsx-H0YX9wLdMr5TqnqT8CGJk-g

window.obaia.Components = {}

window.obaia.Components.maps = (options) => {
    let markers = []
    let infowindow
    let searchMarkers = []
    let markersWithinBounds = []
    let place
    const linkedListWrapper = document.getElementById('obaia-maps-list')

    return {
        map: null,
        markerFeed: options.markerFeed ?? [],
        mapZoom: options.mapZoom ?? 10,
        postalCode: '',
        filter: [],
        searchRadius: 1000,
        resultWindowMarkers: [],
        activeRadius: null,
        activeFilters: null,
        init() {
            const loader = new Loader({
                apiKey: window.obaia.api.googleMaps,
                version: "weekly",
                libraries: ["places", "geometry"]
            })
            const mapOptions = {
                center: {
                    lat: 52.0214282,
                    lng: 5.6706139,
                },
                zoom: this.mapZoom,
                zoomControl: true,
                disableDefaultUI: true,
            }
            loader.load().then((google) => {
                this.map = new google.maps.Map(this.$refs.maps, mapOptions)
                this.autocomplete()
                const bounds = new google.maps.LatLngBounds()
                infowindow = new google.maps.InfoWindow()

                // Render the markers on the map
                for (let i = 0; i < this.markerFeed.length; i++) {
                    const findMarkerById = this.markerFeed[i][Object.keys(this.markerFeed[i])[0]]
                    const marker = new google.maps.Marker({
                        position: new google.maps.LatLng(findMarkerById['adres']['lat'], findMarkerById['adres']['lng']),
                        map: this.map,
                        icon: {
                            url: findMarkerById['meta']['markerIcon'],
                            scaledSize: new google.maps.Size(40, 40),
                        },
                        markerId: findMarkerById['meta']['id'],
                        markerFilter: findMarkerById['meta']['markerFilter'],
                        optimized: true,
                        // scaledSize: new google.maps.Size(30, 30)
                    })
                    bounds.extend(marker.getPosition())
                    google.maps.event.addListener(marker, 'click', () => {
                        // infowindow
                        const contentString =
                            '<div id="content max-w-none px-8 mx-8">' +
                            '<h2 class="font-black font-sans text-xl mb-2 mt-8">' +
                            findMarkerById['meta']['title'] +
                            "</h2>" +
                            "<p class='font-sans text-lg leading-6 font-medium mb-6'>" +
                            findMarkerById['adres']['adres'] + " " + findMarkerById['adres']['number'] +
                            "<br>" + findMarkerById['adres']['postcode'] + " " + findMarkerById['adres']['woonplaats'] +
                            "<br> T " + findMarkerById['meta']['telefoonnummer'] +
                            "<br> <a href='" + findMarkerById['meta']['website'] + "' class='text-primary' target='_blank' rel='noopener noreferrer'>" + findMarkerById['meta']['website'] + "</a>" +
                            "</p>" +
                            "</div>";
                        infowindow.setContent(contentString)
                        infowindow.close()
                        infowindow.open({
                            anchor: marker,
                            map: this.map,
                        })
                        // find list item when clicked
                        // marker.setIcon(markerActiveIcon)
                        // marker.setZIndex(999999)
                        if (linkedListWrapper.querySelector('.is-current')) {
                            linkedListWrapper.querySelector('.is-current').classList.remove('is-current', '!opacity-100')
                        }
                        const targetElement = document.getElementById('marker-' + findMarkerById['meta']['id'])
                        if (targetElement) {
                            targetElement.classList.add('is-current', '!opacity-100')
                            // Scroll the targetElement into view within the linkedListWrapper
                            targetElement.scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest', // 'nearest' works well for all situations
                                inline: 'start'
                            })
                        }
                    })
                    markers.push(marker)
                }
                // this.map.fitBounds(bounds)
            }).catch(e => {
                // do something
            })
        },
        reset() {
            this.filters = []
            this.activeRadius = null
            this.activeFilters = null
            this.$refs.autocomplete.value = ''
            this.clearSearchmarkers()
            this.updateMarkerVisibility()
        },
        findMarker(event) {
            console.log(event.target)
            // Retrieve marker ID from the clicked div
            const markerId = parseInt(event.target.getAttribute('data-marker-id'))
            console.log(markerId)
            // Find the marker with the matching ID
            const marker = markers.find(marker => marker.markerId === markerId)
            console.log(marker)
            if (marker) {
                // Make the marker bounce
                marker.setAnimation(google.maps.Animation.BOUNCE)

                // Optionally, stop bouncing after 3 seconds (3000 milliseconds)
                setTimeout(() => {
                    marker.setAnimation(null)
                }, 2900)

                this.map.panTo(marker.getPosition())
            }
        },
        clearSearchmarkers() {
            // Clear out the old markers + circles
            searchMarkers.forEach((marker) => {
                marker[0].setMap(null)
                marker[1].setMap(null)
            });
            searchMarkers = []
        },
        updateMarkerVisibility() {
            const bounds = new google.maps.LatLngBounds()
            let activateFitBounds = false
            markersWithinBounds = []
            markers.forEach((marker, i) => {
                const inRadius = this.activeRadius ? google.maps.geometry.spherical.computeDistanceBetween(marker.getPosition(), this.activeRadius.center) <= this.activeRadius.radius : true
                const matchesFilter = this.filter.length === 0 || this.filter.some(filter => filter === marker.markerFilter)

                marker.setVisible(inRadius && matchesFilter)
                // bounds.extend(marker.getPosition())
                if (marker.getVisible()) {
                    bounds.extend({lat: this.markerFeed[i][marker.markerId]['adres']['lat'], lng: this.markerFeed[i][marker.markerId]['adres']['lng']})
                    markersWithinBounds.push(this.markerFeed[i][marker.markerId])
                    activateFitBounds = true
                }
            })
            if (activateFitBounds) {
                this.map.fitBounds(bounds)
            }
            if (this.activeRadius || this.filter.length) {
                this.resultWindowMarkers = markersWithinBounds
            } else {
                this.resultWindowMarkers = []
            }
        },
        filterMarkers() {
            infowindow.close()
            this.updateMarkerVisibility()
            // for (let i = 0; i < markers.length; i++) {
            //     let marker = markers[i]
            //     const shouldShowMarker = this.filter.length === 0 || this.filter.some((filter) => filter === marker.markerFilter)
            //     if (shouldShowMarker) {
            //         marker.setVisible(true)
            //     } else {
            //         marker.setVisible(false)
            //     }
            // }
        },
        setRadius() {
            if (!place) {
                return
            }
            this.clearSearchmarkers()
            // Create a marker for each place.
            let markerWithRadius = new google.maps.Marker({
                map: this.map,
                // icon,
                title: place.name,
                position: place.geometry.location,
            })
            const circle = new google.maps.Circle({
                strokeColor: "#87C771",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#87C771",
                fillOpacity: 0.35,
                map: this.map,
                center: markerWithRadius.center,
                radius: parseInt(this.searchRadius)
            })

            circle.bindTo('center', markerWithRadius, 'position')

            searchMarkers.push(
                [
                    markerWithRadius, circle
                ]
            )
            this.activeRadius = {center: circle.getCenter(), radius: circle.getRadius()}

            // Update marker visibility based on new radius without resetting filters
            this.updateMarkerVisibility()
            // // chek if location markers are in radius of circle
            // for (let i = 0; i < markers.length; i++) {
            //     const marker = markers[i]
            //     // if marker is within area
            //     let markerPosition = marker.getPosition()
            //     if (google.maps.geometry.spherical.computeDistanceBetween(markerPosition, circle.getCenter()) <= circle.getRadius()) {
            //         marker.setVisible(true)
            //         markersWithinBounds.push(this.markerFeed[i][marker.markerId])
            //     } else {
            //         marker.setVisible(false)
            //     }
            //
            // }
            // this.resultWindowMarkers = markersWithinBounds

        },
        autocomplete() {
            const options = {
                componentRestrictions: {country: "nl"}
            }
            const autocomplete = new google.maps.places.Autocomplete(this.$refs.autocomplete, options)
            // Store the autocomplete results
            let autocompleteResults = []

            this.$refs.autocomplete.addEventListener('keydown', function (event) {
                if (event.code === 'Enter' || event.keyCode === 13) {
                    event.preventDefault()
                    // Trigger ArrowDown to highlight the first suggestion
                    google.maps.event.trigger(this, 'keydown', {
                        keyCode: 40,
                        code: 'ArrowDown'
                    })
                    // Trigger Enter to select the highlighted suggestion
                    google.maps.event.trigger(this, 'keydown', {
                        keyCode: 13,
                        code: 'Enter'
                    })
                }
            });

            autocomplete.addListener("place_changed", () => {
                place = autocomplete.getPlace()
                autocompleteResults = place.predictions || []
                if (!place.geometry || !place.geometry.location) {
                    // User entered the name of a Place that was not suggested and
                    // pressed the Enter key, or the Place Details request failed.
                    window.alert("No details available for input: '" + place.name + "'")
                    return
                }
                this.map.setCenter(place.geometry.location)
                // const bounds = new google.maps.LatLngBounds()

                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry")
                    return
                }

                this.setRadius()
            })


        }
    }
}

// window.obaia.Components.swiper = (options) => {
//     return {
//         swiperConstance: '',
//         toggleAutoplay() {
//             if (this.swiperConstance.autoplay.running) {
//                 this.swiperConstance.autoplay.stop()
//             } else {
//                 this.swiperConstance.autoplay.start()
//             }
//         },
//         init() {
//             let initialSlideIndex = 0
//             if (options.initialSlide !== '' && typeof options.initialSlide === 'string') {
//                 initialSlideIndex = Array.prototype.indexOf.call(this.$el.querySelectorAll('.swiper-slide'), this.$el.querySelector('#' + options.initialSlide))
//             }
//             const thisElem = this.$el
//             console.log(options.creativeEffect)
//             window.addEventListener('load', () => {
//                 this.swiperConstance = new Swiper(thisElem, {
//                     modules: [Navigation, Pagination, Mousewheel, EffectCreative, Autoplay, FreeMode],
//                     autoplay: options.autoplay ?? false,
//                     speed: options.speed ?? 300,
//                     freeMode: options.freeMode ?? false,
//                     // cssMode: options.cssMode ?? false,
//                     grabCursor: options.grabCursor ?? false,
//                     loop: options.loop ?? false,
//                     autoHeight: options.autoHeight ?? false,
//                     slidesPerView: options.slidesPerView ?? 1,
//                     centeredSlides: options.centeredSlides ?? false,
//                     spaceBetween: options.spaceBetween ?? 16,
//                     initialSlide: options.initialSlide ? initialSlideIndex : options.centeredSlides ? this.$el.querySelectorAll('.swiper-slide').length / 2 : 0,
//                     mousewheel: {
//                         forceToAxis: true,
//                     },
//                     navigation: {
//                         prevEl: this.$refs.swiperPrev ?? thisElem.closest('section').querySelector('.js-swiper-button-prev'),
//                         nextEl: this.$refs.swiperNext ?? thisElem.closest('section').querySelector('.js-swiper-button-next'),
//                     },
//                     pagination: options.pagination ? {
//                         el: thisElem.closest('section').querySelector('.js-swiper-pagination'),
//                         clickable: true,
//                         renderBullet: function (index, className) {
//                             let names = []
//                             thisElem.querySelectorAll('.swiper-slide').forEach((slide) => {
//                                 names.push(slide.getAttribute('data-name'))
//                             })
//                             return '<li class="cursor-pointer antialiased ' + className + '">' + names[index] + '</li>';
//                         }
//                     } : {},
//                     breakpoints: {
//                         ...options.breakpoints
//                     },
//                     on: {
//                         init: function () {
//                             if (options.autoplay) {
//                                 this.autoplay.start()
//                             }
//                         },
//                         autoplayResume: function () {
//                             this.autoplay.start()
//                         },
//                         autoplayPause: function () {
//                             this.autoplay.stop()
//                         },
//                     },
//                 })
//                 console.log(this.swiperConstance)
//             })
//         }
//     }
// }
window.obaia.Components.menu = function (e = {
    open: !1
}) {
    return {
        init() {
            this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]')),
                this.$watch("open", (() => {
                        this.open && (this.activeIndex = -1)
                    }
                ))
        },
        activeDescendant: null,
        activeIndex: null,
        items: null,
        open: e.open,
        focusButton() {
            this.$refs.button.focus()
        },
        onButtonClick() {
            this.open = !this.open,
            this.open && this.$nextTick((() => {
                    this.$refs["menu-items"].focus()
                }
            ))
        },
        onButtonEnter() {
            this.open = !this.open,
            this.open && (this.activeIndex = 0,
                this.activeDescendant = this.items[this.activeIndex].id,
                this.$nextTick((() => {
                        this.$refs["menu-items"].focus()
                    }
                )))
        },
        onArrowUp() {
            if (!this.open)
                return this.open = !0,
                    this.activeIndex = this.items.length - 1,
                    void (this.activeDescendant = this.items[this.activeIndex].id);
            0 !== this.activeIndex && (this.activeIndex = -1 === this.activeIndex ? this.items.length - 1 : this.activeIndex - 1,
                this.activeDescendant = this.items[this.activeIndex].id)
        },
        onArrowDown() {
            if (!this.open)
                return this.open = !0,
                    this.activeIndex = 0,
                    void (this.activeDescendant = this.items[this.activeIndex].id);
            this.activeIndex !== this.items.length - 1 && (this.activeIndex = this.activeIndex + 1,
                this.activeDescendant = this.items[this.activeIndex].id)
        },
        onClickAway(e) {
            if (this.open) {
                const t = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
                this.open = !1,
                e.target.closest(t) || this.focusButton()
            }
        }
    }
}

window.obaia.Components.popoverGroup = function () {
    return {
        __type: "popoverGroup",
        init() {
            let e = t => {
                    document.body.contains(this.$el) ? t.target instanceof Element && !this.$el.contains(t.target) && window.dispatchEvent(new CustomEvent("close-popover-group", {
                        detail: this.$el
                    })) : window.removeEventListener("focus", e, !0)
                }
            ;
            window.addEventListener("focus", e, !0)
        }
    }
}

window.obaia.Components.popover = function ({open: e = !1, focus: t = !1} = {}) {
    const i = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
    return {
        __type: "popover",
        open: e,
        init() {
            t && this.$watch("open", (e => {
                    e && this.$nextTick((() => {
                            !function (e) {
                                const t = Array.from(e.querySelectorAll(i));
                                !function e(i) {
                                    void 0 !== i && (i.focus({
                                        preventScroll: !0
                                    }),
                                    document.activeElement !== i && e(t[t.indexOf(i) + 1]))
                                }(t[0])
                            }(this.$refs.panel)
                        }
                    ))
                }
            ));
            let e = i => {
                    if (!document.body.contains(this.$el))
                        return void window.removeEventListener("focus", e, !0);
                    let n = t ? this.$refs.panel : this.$el;
                    if (this.open && i.target instanceof Element && !n.contains(i.target)) {
                        let e = this.$el;
                        for (; e.parentNode;)
                            if (e = e.parentNode,
                            e.__x instanceof this.constructor) {
                                if ("popoverGroup" === e.__x.$data.__type)
                                    return;
                                if ("popover" === e.__x.$data.__type)
                                    break
                            }
                        this.open = !1
                    }
                }
            ;
            window.addEventListener("focus", e, !0)
        },
        onEscape() {
            this.open = !1,
            this.restoreEl && this.restoreEl.focus()
        },
        onClosePopoverGroup(e) {
            e.detail.contains(this.$el) && (this.open = !1)
        },
        toggle(e) {
            this.open = !this.open,
                this.open ? this.restoreEl = e.currentTarget : this.restoreEl && this.restoreEl.focus()
        }
    }
}